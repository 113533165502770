import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

/**
 * @doc when we need to copy the content to another textbox
 * [
 *	'type' => 'textarea',
 *	'name' => 'address',
 *	'class' => 'col-md-12',
 * ],
 */

const CustomTextareaComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isTouched, setIsTouched] = useState(false);

	useEffect(() => {
		setInternalValue(value);
	}, [value]);

	useEffect(() => {
		validate(internalValue);
	}, [internalValue]);

	useEffect(() => {
		validate(value);
	}, []);

	const handleChange = (newValue) => {
		setInternalValue(newValue);
		onChange(name, newValue, isValid);
	};

	const validate = (currentValue) => {
		const fieldInfo = getFieldInfo();

		let trimmedValue = currentValue;

		if (fieldInfo.upperCase) {
			trimmedValue = trimmedValue.toUpperCase();
		}

		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, trimmedValue, true);
			return;
		}

		let valid = true;
		let message = "";

		// Check minLength and maxLength only if the field is not empty
		if (trimmedValue !== "") {
			if (
				fieldInfo.minLength &&
				trimmedValue.length < fieldInfo.minLength
			) {
				valid = false;
				message = `Minimum length is ${fieldInfo.minLength} characters.`;
			} else if (
				fieldInfo.maxLength &&
				trimmedValue.length > fieldInfo.maxLength
			) {
				valid = false;
				message = `Maximum length is ${fieldInfo.maxLength} characters.`;
			}
		}

		// Check required
		if (fieldInfo.required && trimmedValue === "") {
			valid = false;
			message = "This field is required.";
		}

		setIsValid(valid);
		setErrorMessage(message);
		onChange(name, trimmedValue, valid);
	};

	const handleFocus = () => {
		setIsTouched(true);
		validate(internalValue);
	};

	const getFieldInfo = () => {
		return {
			required: Boolean(type && type.required),
			minLength: type && type.minLength,
			maxLength: type && type.maxLength,
			readOnly: type && type.readOnly,
			upperCase: type && type.upperCase,
			rows: type && type.rows,
		};
	};

	return (
		<div className={`${className}`}>
			<TextField
				id={name}
				label={`${label}`}
				multiline
				rows={getFieldInfo().rows || 2}
				variant="standard"
				size="small"
				fullWidth
				type={type && type.type}
				value={internalValue}
				onChange={(e) => handleChange(e.target.value)}
				onFocus={handleFocus}
				error={!isValid && isTouched}
				helperText={!isValid && isTouched && errorMessage}
				className={getFieldInfo().readOnly ? "readonly-text" : ""}
				InputProps={{
					readOnly: getFieldInfo().readOnly,
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomTextareaComponent;
