import React, { useEffect, useState } from "react";
import ApiService from "../../../service/ApiService";
import CustomInputComponent from "./fields/CustomInputComponent";
import CustomInputEmailComponent from "./fields/CustomInputEmailComponent";
import CustomInputZipComponent from "./fields/CustomInputZipComponent";
import CustomInputPhoneComponent from "./fields/CustomInputPhoneComponent";
import CustomSelectComponent from "./fields/CustomSelectComponent";
import CustomSwitchComponent from "./fields/CustomSwitchComponent";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SessionService from "../../../service/SessionService";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CustomInputNumberComponent from "./fields/CustomInputNumberComponent";
import CustomInputCCNAmountComponent from "./fields/CustomInputCCNAmountComponent";
import CustomInputCCNTokenizerComponent from "./fields/CustomInputCCNTokenizerComponent";
import CustomMinMaxDefaultComponent from "./fields/CustomMinMaxDefaultComponent";
import CustomBankNameLookupComponent from "./fields/CustomBankNameLookupComponent";
import CustomMultiSelectComponent from "./fields/CustomMultiSelectComponent";
import CustomTextareaComponent from "./fields/CustomTextareaComponent";
import CustomDateInputComponent from "./fields/CustomDateInputComponent";
import CustomInputSSNComponent from "./fields/CustomInputSSNComponent";
import CustomInputEINComponent from "./fields/CustomInputEINComponent";
import CustomUploadComponent from "./fields/CustomUploadComponent";
import CustomDownloadComponent from "./fields/CustomDownloadComponent";
import CustomTypographyComponent from "./fields/CustomTypographyComponent";
import CustomIncrementalComponent from "./fields/CustomIncrementalComponent";
import CustomSelectHierarchyComponent from "./fields/CustomSelectHierarchyComponent";
import CustomContractLookupComponent from "./fields/CustomContractLookupComponent";
import CustomFileComponent from "./fields/CustomFileComponent";
const theme = createTheme({
	palette: {
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const CustomDynamicFormsMUI = ({
	showDebug = false,
	setLoading,
	loading,
	apiBase,
	successRedirect = null,
	isUpdateMode = false,
	onFormSubmitSuccess,
	model,
	modelLabel,
	modelId,
	formDefinition,
	createEndpointOverride = null,
	updateEndpointOverride = null,
	extraData = {},
	sendAsFlatStructure = false,
	suppressSuccessToast = false,
	isSupportFileUpload = false,
	tokenizerUrl = null,
}) => {
	const [fieldsInfo, setFieldsInfo] = useState(null);
	const [formState, setFormState] = useState({});
	const [isValid, setIsValid] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		validateAllFields();
	}, [formState]);

	useEffect(() => {
		if (formDefinition) {
			setFieldsInfo(formDefinition.fields);

			// Initialize formState based on the values
			const initialFormState = {};
			Object.entries(formDefinition.fields).forEach(([key, field]) => {
				let my_value = field.value ?? "";
				let isValid = true;

				// Apply upperCase only if my_value is a string
				if (
					field.upperCase &&
					field.type !== "select" && // ignore selectbox value
					typeof my_value === "string"
				) {
					my_value = my_value.toUpperCase();
				}

				// Prepend +1 for telephone inputs if in update mode
				if (field.type === "tel" && isUpdateMode) {
					my_value = `+1${my_value}`;
				} else if (field.type === "tel" && !isUpdateMode) {
					my_value = field.value;
				}

				// Apply prefix if not in update mode and my_value is a string
				if (
					field.prefix &&
					!isUpdateMode &&
					typeof my_value === "string"
				) {
					my_value = `${field.prefix}${my_value}`;
				}

				// Specific validation for amount and total fields
				if (key === "amount" || key === "total") {
					const numericValue = parseFloat(
						my_value.replace(/[^0-9.-]+/g, "")
					);
					isValid = !isNaN(numericValue) && numericValue > 0;
				}

				if (
					apiBase === "/payoff-early-policy" &&
					key === "struct_store_id" && 
					!isUpdateMode &&
					modelId
				) {
					my_value = modelId;
				}

				// Specifically for 'model_id', set its value to modelId if modelId is set
				if (key === "model_id" && modelId) {
					my_value = modelId;
				}

				// Specifically for 'model', set its value to model if model is set
				if (key === "model" && model) {
					my_value = model;
				}

				initialFormState[key] = {
					value: my_value,
					// isValid: !field.required || field.readOnly || false,
					isValid: isValid,
				};
			});

			setFormState(initialFormState);
			// validateAllFields(); // Make sure to validate all fields after setting the initial form state
			setIsValid(false);
		}
	}, [formDefinition]);

	const renderFormItem = (item, index) => {
		const { name, class: className, ...otherProps } = item;
		const fieldInfo = fieldsInfo && fieldsInfo[name];
		const isRequired = fieldInfo && fieldInfo.required;

		if (item.type === "div") {
			return (
				<div
					key={index}
					className={`${item.class} ${item.style ? item.style : ""}`}
				>
					{Array.isArray(item.items) &&
						item.items.map((nestedItem, nestedIndex) =>
							renderFormItem(nestedItem, nestedIndex)
						)}
				</div>
			);
		} else if (item.type === "input") {
			return (
				<CustomInputComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					varian="Filled"
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetTextComponent={item?.targetTextComponent || false}
				/>
			);
		} else if (item.type === "hidden") {
			return (
				<CustomInputComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					varian="Filled"
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetTextComponent={item?.targetTextComponent || false}
				/>
			);
		} else if (item.type === "textarea") {
			return (
				<CustomTextareaComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					varian="Filled"
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "email") {
			return (
				<CustomInputEmailComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetTextComponent={item?.targetTextComponent || false}
				/>
			);
		} else if (item.type === "zip") {
			return (
				<CustomInputZipComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					target_city={item.targetCity}
					target_state={item.targetState}
				/>
			);
		} else if (item.type === "phone") {
			return (
				<CustomInputPhoneComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "select") {
			return (
				<CustomSelectComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "select-hierarchy") {
			return (
				<CustomSelectHierarchyComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value}
					formState={formState}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetSelectComponent={item.targetSelectComponent}
					targetModel={item.targetModel}
				/>
			);
		} else if (item.type === "number") {
			return (
				<CustomInputNumberComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "ccn_amount") {
			return (
				<CustomInputCCNAmountComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					structStoreId={formState["struct_store_id"]?.value}
					profileId={formState["profile_id"]?.value}
					token={formState["token"]?.value}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "ccn_tokenizer") {
				return (
					<CustomInputCCNTokenizerComponent
						key={index}
						name={name}
						label={fieldInfo && fieldInfo.label}
						type={fieldInfo}
						value={formState[name]?.value}
						onChange={(fieldName, value, pIsValid) =>
							handleChange(fieldName, value, pIsValid)
						}
						className={className}
						required={isRequired}
						style={fieldInfo?.style}
						targetToken={item.targetToken}
						tokenizerUrl={tokenizerUrl}
					/>
				);
		} else if (item.type === "min-max-default") {
			return (
				<CustomMinMaxDefaultComponent
					key={index}
					target_min={item.targetMin}
					target_max={item.targetMax}
					formState={formState}
					defaultName={name}
					defaultLabel={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					defaultValue={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "bank-name-lookup") {
			return (
				<CustomBankNameLookupComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetBankName={item.targetBankName}
				/>
			);
		} else if (item.type === "multi-select") {
			return (
				<CustomMultiSelectComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					options={fieldInfo?.options || []}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "switch") {
			return (
				<CustomSwitchComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "date") {
			return (
				<div className={className}>
					<CustomDateInputComponent
						key={index}
						name={name}
						label={fieldInfo && fieldInfo.label}
						type={fieldInfo}
						value={formState[name]?.value || ""}
						onChange={(fieldName, value, pIsValid) =>
							handleChange(fieldName, value, pIsValid)
						}
						required={isRequired}
						style={fieldInfo?.style}
						inputFormat={fieldInfo?.inputFormat}
					/>
				</div>
			);
		} else if (item.type === "ssn") {
			return (
				<CustomInputSSNComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "ein") {
			return (
				<CustomInputEINComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "contract-lookup") {
			return (
				<CustomContractLookupComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					type={fieldInfo}
					value={formState[name]?.value || ""}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					formState={formState}
					loading={loading}
					setLoading={setLoading}
				/>
			);
		} else if (item.type === "combine-inputs") {
			let componentValue = "";

			// Additional check to ensure fieldInfo is not null or undefined
			if (fieldInfo) {
				// Ensure sourceComponents is an array before proceeding
				if (Array.isArray(fieldInfo.sourceComponents)) {
					const separator = fieldInfo.separator || "";
					const sourceComponents = fieldInfo.sourceComponents;

					componentValue = sourceComponents.reduce(
						(acc, sourceComponent, index) => {
							// Ensure formState for the sourceComponent exists before accessing its value
							if (formState[sourceComponent]) {
								const valueToAdd =
									formState[sourceComponent].value || "";
								if (index > 0 && valueToAdd) {
									return `${acc}${separator}${valueToAdd}`;
								}
								return acc + valueToAdd;
							}
							return acc;
						},
						""
					);
				} else {
					console.error(
						"sourceComponents is not an array or is missing for",
						item
					);
				}
			} else {
				console.error("fieldInfo is null for", item);
				// Consider handling this case more gracefully, depending on your application's needs
			}

			return (
				<CustomInputComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					varian="Filled"
					type={fieldInfo}
					value={componentValue}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
					className={className}
					required={isRequired}
					style={fieldInfo?.style}
					targetTextComponent={false}
				/>
			);
		} else if (item.type === "upload") {
			return (
				<CustomUploadComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					modelName={fieldInfo && fieldInfo.modelName}
					modelId={fieldInfo && fieldInfo.modelId}
					className={className}
					allowedFileExtensions={
						fieldInfo && fieldInfo.allowedFileExtensions
					}
					maxCount={fieldInfo && fieldInfo.maxCount}
					maxFileSize={fieldInfo && fieldInfo.maxFileSize}
					listType={fieldInfo && fieldInfo.listType}
				/>
			);
		} else if (item.type === "download") {
			return (
				<CustomDownloadComponent
					key={index}
					// fileType={fieldInfo?.fileType}
					// fileName={fieldInfo?.fileName}
					// fileData={fieldInfo?.fileData}
					// filePath={fieldInfo?.filePath}
					className={className}
					files={fieldInfo?.files || []}
					downloadApi={fieldInfo?.downloadApi || ""}
				/>
			);
		} else if (item.type === "file") {
			return (
				<CustomFileComponent
					key={index}
					name={name}
					label={fieldInfo && fieldInfo.label}
					className={className}
					allowedFileExtensions={
						fieldInfo && fieldInfo.allowedFileExtensions
					}
					maxCount={fieldInfo && fieldInfo.maxCount}
					maxFileSize={fieldInfo && fieldInfo.maxFileSize}
					listType={fieldInfo && fieldInfo.listType}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
				/>
			);
		} else if (item.type === "typography") {
			return (
				<CustomTypographyComponent
					key={index}
					className={className}
					href={fieldInfo?.href}
					hrefTarget={fieldInfo?.hrefTarget}
					value={formState[name]?.value || ""}
					text={fieldInfo?.text}
					variant={fieldInfo?.variant}
					style={fieldInfo?.style}
				/>
			);
		} else if (item.type === "incremental") {
			return (
				<CustomIncrementalComponent
					key={index}
					value={formState[name]?.value || ""}
					label={fieldInfo && fieldInfo.label}
					minValue={fieldInfo?.minValue}
					maxValue={fieldInfo?.maxValue}
					incrementer={fieldInfo?.incrementer}
					className={className}
					style={fieldInfo?.style}
					onChange={(fieldName, value, pIsValid) =>
						handleChange(fieldName, value, pIsValid)
					}
				/>
			);
		}
		return null;
	};

	const renderButtons = () => {
		if (formDefinition && formDefinition.buttons) {
			return (
				<div className={`row g-3`}>
					{formDefinition.buttons.map((button, index) => (
						<div
							key={index}
							className={`col ${
								button.align ? `text-${button.align}` : ""
							}`}
						>
							<Button
								type={button.type}
								variant="contained"
								color="primary"
								onClick={(event) =>
									handleButtonClick(event, button)
								}
								disabled={!isValid}
							>
								<span className="font-normal">
									{button.label}
								</span>
							</Button>
						</div>
					))}
				</div>
			);
		}
		return null;
	};

	const handleChange = (fieldName, value, pIsValid) => {
		setFormState((prevState) => ({
			...prevState,
			[fieldName]: {
				...prevState[fieldName],
				value: value,
				isValid: pIsValid,
			},
		}));
		validateAllFields();
	};

	const validateAllFields = () => {
		let isValidForm = true;

		Object.entries(formState).forEach(([key, field]) => {
			const fieldInfo = fieldsInfo[key];
			if (fieldInfo?.required) {
				if (field.isValid === false) {
					isValidForm = false;
				} else if (
					fieldInfo.type === "switch" &&
					field.value === false
				) {
					isValidForm = false;
				} else if (
					field.value === null ||
					field.value === undefined ||
					field.value === ""
				) {
					isValidForm = false;
				}
			}
		});

		setIsValid(isValidForm);
		return isValidForm;
	};

	const handleButtonClick = async (event, button) => {
		event.preventDefault();

		// Validate all fields before submitting
		const isValidForm = validateAllFields();

		if (isValidForm && button.type === "submit") {
			const formDataObject = Object.fromEntries(
				Object.entries(formState).map(([fieldName, field]) => {
					const myPrefix = fieldsInfo[fieldName]?.prefix;

					if (myPrefix !== undefined) {
						if (field.value.length > 0) {
							return [
								fieldName,
								field.value.replace(myPrefix, ""),
							];
						} else {
							return [fieldName, field.value];
						}
					} else {
						return [fieldName, field.value];
					}
				})
			);

			// Merge extraData with formDataObject
			let finalData;
			if (sendAsFlatStructure) {
				finalData = {
					...extraData,
					...formDataObject, // Spread formDataObject at the same level as extraData
					...extraData.data, // Ensure any data from extraData.data is also at the same level
				};
			} else {
				finalData = {
					...extraData,
					data: { ...formDataObject, ...extraData.data },
				};
			}

			setLoading(true);

			const formSubmitUrl = isUpdateMode
				? updateEndpointOverride || `${apiBase}/update?id=${modelId}`
				: createEndpointOverride || `${apiBase}/create`;

			let postMethodName =
				isSupportFileUpload === true ? "postFormData" : "post";

			await ApiService[postMethodName](formSubmitUrl, finalData)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.isOk === true
					) {
						setLoading(false);
						//close modal / partial form callback
						if (onFormSubmitSuccess) {
							onFormSubmitSuccess(response, formState);
						}
						// show success message
						const actionWord = isUpdateMode ? "updated" : "created";
						const dynamicToastMessage = `${modelLabel} ${actionWord} successfully!`;
						if (!suppressSuccessToast) {
							if (isUpdateMode) {
								// Use toast.info for update
								toast.info(dynamicToastMessage, {
									position: "top-center",
									autoClose: 1000,
								});
							} else {
								// Use toast.success for create
								toast.success(dynamicToastMessage, {
									position: "top-center",
									autoClose: 1000,
								});
							}
						}
						// setFormSubmitted(true);
						SessionService.resetUserHierarchy();
						if (successRedirect) {
							let noRedirectModels = ["leads", "struct_store"];
							if (
								isUpdateMode &&
								noRedirectModels.includes(model)
							) {
								// @todo not redirect
							} else {
								navigate(successRedirect);
							}
						}
					} else {
						setLoading(false);
						console.log(response);
						// Display error message from the response
						toast.error(`Error: ${response.data.msg}`, {
							position: "top-center",
							autoClose: 5000,
						});
						// Optionally, display specific field errors if they exist
						if (response.data.errors) {
							Object.entries(response.data.errors).forEach(
								([field, error]) => {
									toast.error(`${field}: ${error}`, {
										position: "top-center",
										autoClose: 5000,
									});
								}
							);
						}
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					// Display a generic error message or specific error if available
					const errorMessage =
						error.response?.data?.msg ||
						"An unexpected error occurred";
					toast.error(`Error: ${errorMessage}`, {
						position: "top-center",
						autoClose: 5000,
					});
				});
		} else {
			console.log(
				'Form has validation errors or button is not of type "submit". Cannot submit.'
			);
		}
	};

	// Render debug section for localhost
	const renderDebugSection = () => {
		if (window.location.hostname === "localhost") {
			return (
				<div
					style={{
						marginTop: "20px",
						padding: "10px",
						border: "1px solid #ccc",
					}}
				>
					<h3>Debug: formState</h3>
					<pre style={{ fontFamily: "monospace" }}>
						{JSON.stringify(formState, null, 2)}
					</pre>
				</div>
			);
		}
		return null;
	};

	useEffect(() => {
		validateAllFields();
	}, [formState]);

	return (
		<ThemeProvider theme={theme}>
			<form
				className={formDefinition ? formDefinition.form.class : ""}
				{...(isSupportFileUpload && { encType: "multipart/form-data" })}
			>
				{formDefinition
					? formDefinition.layout.map((item, index) =>
							renderFormItem(item, index)
					  )
					: "Loading..."}
				{}
				<hr />
				{renderButtons()}
				{showDebug && renderDebugSection()}
			</form>
		</ThemeProvider>
	);
};

export default CustomDynamicFormsMUI;
