import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./rtoDynamicForm/Header";
import Breadcrumbs from "./rtoDynamicForm/Breadcrumbs";
import CustomDynamicFormsMUI from "./rtoDynamicForm/CustomDynamicFormsMUI";
import ModelActivities from "../ModelActivities";
import pluralize from "pluralize";
import TopModal from "../common/TopModal";
import ApiService from "../../service/ApiService";
import SessionService from "../../service/SessionService";

function DynamicForm({
	loading,
	setLoading,
	model,
	modelId,
	modelLabel,
	service,
	apiBase,
	successRedirectBase,
	scenario = "create",
	updateId: propUpdateId,
	display = "page",
	isOpen,
	onClose,
	fetchData,
	tabs,
	createEndpointOverride,
	updateEndpointOverride,
	definitionOverride,
	extraData,
	sendAsFlatStructure,
	suppressSuccessToast,
	isSupportFileUpload = false,
	tokenizerUrl = null,
}) {
	const { id: routeId } = useParams(); // get the ID from the route if available
	// const effectiveUpdateId = propUpdateId || routeId; // prefer UpdateId over routeId
	const effectiveUpdateId =
		display === "page" || scenario === "create"
			? routeId
			: propUpdateId || routeId;

	const [formData, setFormData] = useState(null);
	// const [formDefinition, setFormDefinition] = useState(null);
	const [formDefinition, setFormDefinition] = useState(
		definitionOverride || null
	);
	const formContentKey = formData ? effectiveUpdateId : "initial"; // Use a unique part of formData or a placeholder

	useEffect(() => {
		const fetchFormDefinition = async () => {
			// For "modal" scenario, only proceed if the form is being displayed
			if (display === "modal" && !isOpen) {
				return;
			}

			// If definitionOverride is provided, use it directly and skip fetching
			if (definitionOverride) {
				setFormDefinition(definitionOverride);
				if (scenario === "update") {
					let formDataValues = {};
					for (const fieldName in definitionOverride.fields) {
						formDataValues[fieldName] =
							definitionOverride.fields[fieldName].value;
					}
					setFormData(formDataValues);
				}
				return;
			}

			const endpoint =
				scenario === "update"
					? `${apiBase}/update?id=${effectiveUpdateId}`
					: `${apiBase}/create`;

			setLoading(true);
			try {
				const response = await ApiService.get(endpoint);
				const data = response.data.data;
				setFormDefinition(data);

				if (scenario === "update") {
					let formDataValues = {};
					for (const fieldName in data.fields) {
						formDataValues[fieldName] =
							data.fields[fieldName].value;
					}
					setFormData(formDataValues);
				}
			} catch (error) {
				console.error("Error fetching form definition:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchFormDefinition();
	}, [
		scenario,
		effectiveUpdateId,
		apiBase,
		setLoading,
		display,
		isOpen,
		definitionOverride,
	]);

	const pageTitle =
		scenario === "update"
			? //? model === "user"
			  //? `Update ${modelLabel}: ${formData?.username}`
			  //: `Update ${modelLabel}: ${formData?.name}`
			  `Update ${modelLabel}`
			: `Create New ${modelLabel}`;
	const successRedirect = successRedirectBase
		? `/${successRedirectBase}`
		: null;

	const breadcrumbs = [
		{ label: "Dashboard", to: "/dashboard" },
		{ label: "Settings", to: "/settings" },
		{ label: pluralize(modelLabel), to: `/${successRedirectBase}` },
		{ label: scenario === "update" ? "Update" : "Create" },
	];

	const formContent = formDefinition && (
		<CustomDynamicFormsMUI
			key={formContentKey}
			loading={loading}
			setLoading={setLoading}
			formDefinition={formDefinition}
			showDebug={true}
			apiBase={apiBase}
			successRedirect={successRedirect}
			createEndpointOverride={createEndpointOverride}
			updateEndpointOverride={updateEndpointOverride}
			extraData={extraData}
			sendAsFlatStructure={sendAsFlatStructure}
			isUpdateMode={scenario === "update"}
			onFormSubmitSuccess={(responseData, formState) => {
				if (display === "modal") {
					onClose(); // Close the modal on successful form submission
					fetchData(); // Fetch data to refresh the list
				}
				if (display === "partial" && fetchData) {
					fetchData(responseData, formState); // Fetch data to refresh the list
				}
			}}
			model={model}
			modelLabel={modelLabel}
			modelId={effectiveUpdateId}
			suppressSuccessToast={suppressSuccessToast}
			isSupportFileUpload={isSupportFileUpload}
			tokenizerUrl={tokenizerUrl}
		/>
	);

	let canModalAccess =
		SessionService.canSystemAdmin() || SessionService.canRtoAdmin();

	return display === "modal" ? (
		<TopModal
			title={pageTitle}
			body={formContent}
			isVisible={isOpen}
			onClose={onClose}
		/>
	) : display === "partial" ? (
		<div className="rto_partial-form">{formContent}</div>
	) : (
		<>
			<Header pageTitle={pageTitle} />
			<div className="rto_form-sections">
				<div className="rto_content-div">
					<Breadcrumbs breadcrumbs={breadcrumbs} />
					<div className="rto_title-div">
						<h1 className="rto_page-title">{pageTitle}</h1>
					</div>
					{formContent}
					{/* TODO: Remove the model !== "struct_activity" condition & make dynamic */}
					{scenario === "update" &&
						formData &&
						canModalAccess &&
						model !== "struct_activity" && (
							<>
								<br />
								<ModelActivities
									redirectPath={`${successRedirectBase}/${effectiveUpdateId}`}
									modelId={effectiveUpdateId}
									model={model}
									loading={loading}
									setLoading={setLoading}
									tabs={tabs}
								/>
							</>
						)}
				</div>
			</div>
		</>
	);
}

export default DynamicForm;
