import React, {useState, useEffect, useRef} from "react";
import {Link, Form} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Dropdown} from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import RtoWideLogo from "../images/rto-wide-logo.svg";
import {
    FaBars,
    FaKey,
    FaHome,
    FaFileContract,
    FaCogs,
    FaUserPlus,
    FaUserCog,
} from "react-icons/fa";
import {FaChartPie, FaBook, FaHeadset, FaChartLine} from "react-icons/fa6";
import {MdMobileFriendly} from "react-icons/md";
import {GiReceiveMoney} from "react-icons/gi";
import {LuLogOut} from "react-icons/lu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import axios from "axios";
import SessionService from "../service/SessionService";
import {ToastContainer} from "react-toastify";
import ApiService from "../service/ApiService";

function AuthHeader() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);
    const handleCloseOffcanvas = () => setShowOffcanvas(false);

    const [selectedStore, setSelectedStore] = useState(
        SessionService.getUserStore()
    );

    const intervalId = useRef();

    useEffect(() => {
        let isMounted = true;
        let intervalSet = false;

        const redirectToLogin = () => {
            if (window.location.hostname !== "localhost") {
                SessionService.clearSession();
                window.location.replace("/login");
            }
        };

        const checkAuthStatus = () => {
            const authToken = localStorage.getItem("token");
            if (!authToken) {
                console.error("No auth token found.");
                if (isMounted) {
                    redirectToLogin();
                }
                return;
            }

            if (!intervalSet) {
                ApiService
                    .get("/auth/status")
                    .then((response) => {
                        if (!response.data.isOk) {
                            if (isMounted) {
                                redirectToLogin();
                            }
                            return;
                        }

                        const interval = response.data.interval;
                        if (isMounted && !intervalId.current) {
                            intervalId.current = setInterval(
                                checkAuthStatus,
                                interval
                            );
                            intervalSet = true;
                        }

                        SessionService.setUserRoutes(response.data.userRoutes);
                        SessionService.setIsVpn(response.data.isVPN);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        if (isMounted) {
                            redirectToLogin();
                        }
                    });
            }
        };

        // Initial call
        checkAuthStatus();

        // Clean up function to clear the interval when the component unmounts
        return () => {
            isMounted = false;
            if (intervalId.current) {
                clearInterval(intervalId.current);
                intervalId.current = null;
            }
        };
    }, []);

    const user = SessionService.getUserInfo();
    const userHierarchy = SessionService.getUserHierarchy();

    // console.log(userHierarchy);

    // Defensive check for null or undefined values
    if (!user || !userHierarchy) {
        return null; // or return some placeholder component or an error message
    }

    const full_name = user.first_name + " " + user.last_name;
    const stores = userHierarchy.sto_list ? userHierarchy.sto_list : {};

    const handleChange = (event) => {
        const storeValue = event.target.value;
        setSelectedStore(storeValue); // Update the selected store state

        ApiService.post("/dashboard/ajax-change-store", {value: storeValue})
            .then((response) => {
                const data = response.data;
                if (data.isOk) {
                    SessionService.setUserStore(data.data.struct_store_id);
                    SessionService.setStoreReportFlags(data.storeReportFlags);
                    window.location.reload();
                } else {
                    console.error(data.msg);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const sortedStores = Object.entries(JSON.parse(stores)).sort(([, nameA], [, nameB]) => {
        return nameA.localeCompare(nameB);
    });

    return (
        <>
            <Navbar
                fixed="top"
                variant=""
                expand={false}
                style={{zIndex: 40}}
            >
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <img
                                src={RtoWideLogo}
                                loading="lazy"
                                alt=""
                                width="225px"
                                className="brand"
                            />
                        </Link>
                    </Navbar.Brand>
                    <div className="d-flex rto_d-flex">
                        {SessionService.canCompanyAdmin() && (

                            <div className="d-flex rto_d-flex rto_align-items-center rto_nav-div rto_show-dektop">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
										<span className="input-group-text">
											Current Store
										</span>
                                    </div>

                                    <select
                                        id="store_select"
                                        name="store_select"
                                        className="form-control mb-0 w-select"
                                        onChange={handleChange}
                                        value={selectedStore}
                                        style={{maxWidth: "150px"}}
                                    >
                                        {sortedStores.map(([sto_id, sto_name]) => (
                                            <option value={sto_id} key={sto_id}>
                                                {sto_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className="d-flex rto_d-flex rto_align-items-center rto_nav-div rto_show-dektop">
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant
                                    id="user-dropdown"
                                    className="rto_topplusbutton w-inline-block"
                                >
                                    <div
                                        className="rto_text-block-3"
                                        style={{marginTop: "-1px"}}
                                    >
                                        +
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Form action="/logout" method="post">
                                        <button className="nav-link dropdown-item rounded">
                                            <div className="d-flex align-items-center">
                                                <LuLogOut/>
                                                &nbsp;&nbsp;Logout
                                            </div>
                                        </button>
                                    </Form>
                                    <DropdownItem
                                        to="/change-password"
                                        className="nav-link dropdown-item rounded text-[#22557b]"
                                        as={Link}
                                    >
                                        <div className="d-flex align-items-center">
                                            <FaUserCog/>
                                            &nbsp;&nbsp;Change Password
                                        </div>
                                    </DropdownItem>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                <div className="rto_nametext">{full_name}</div>
                            </div>
                        </div>
                        <Navbar.Toggle
                            aria-controls="rto-offcanvas"
                            onClick={handleShowOffcanvas}
                        >
                            <FaBars/>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            id="rto-offcanvas"
                            placement="end"
                            show={showOffcanvas}
                            onHide={handleCloseOffcanvas}
                        >
                            <Offcanvas.Header closeButton>
                                <div>
                                    <div className="d-flex justify-space-between">
                                        {SessionService.canCompanyAdmin() && (
                                            <div className="d-flex rto_d-flex rto_align-items-center rto_nav-div rto_hide-desktop">
                                                <div className="input-group input-group-sm">
                                                    <div className="input-group-prepend">
														<span className="input-group-text">
															Current Store
														</span>
                                                    </div>
                                                    <select
                                                        id="store_select2"
                                                        name="store_select2"
                                                        className="form-control mb-0 w-select"
                                                        onChange={handleChange}
                                                        value={selectedStore}
                                                        style={{
                                                            maxWidth: "150px",
                                                        }}
                                                    >
                                                        {Object.entries(
                                                            JSON.parse(stores)
                                                        ).map(
                                                            ([
                                                                 sto_id,
                                                                 sto_name,
                                                             ]) => (
                                                                <option
                                                                    value={
                                                                        sto_id
                                                                    }
                                                                    key={sto_id}
                                                                >
                                                                    {sto_name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <div className="d-flex rto_d-flex rto_align-items-center rto_nav-div rto_hide-desktop">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant
                                                    id="user-dropdown"
                                                    className="rto_topplusbutton w-inline-block"
                                                >
                                                    <div
                                                        className="rto_text-block-3"
                                                        style={{
                                                            marginTop: "-1px",
                                                        }}
                                                    >
                                                        +
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu drop="center">
                                                    <Form
                                                        action="/logout"
                                                        method="post"
                                                    >
                                                        <button className="nav-link dropdown-item rounded">
                                                            <div className="d-flex align-items-center">
                                                                <LuLogOut/>
                                                                &nbsp;&nbsp;Logout
                                                            </div>
                                                        </button>
                                                    </Form>
                                                    <DropdownItem
                                                        to="/change-password"
                                                        className="nav-link dropdown-item rounded"
                                                        as={Link}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <FaUserCog/>
                                                            &nbsp;&nbsp;Change
                                                            Password
                                                        </div>
                                                    </DropdownItem>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                                    {SessionService.canRtoAdmin() && (
                                        <Link
                                            to="/admin"
                                            className="nav-link"
                                            onClick={handleCloseOffcanvas}
                                        >
                                            <div className="d-flex align-items-center text-lg font-medium">
                                                <FaKey/>
                                                &nbsp;&nbsp;Admin
                                            </div>
                                        </Link>
                                    )}
                                    {SessionService.canSystemAdmin() && (
                                        <Link
                                            to="/admintools"
                                            className="nav-link"
                                            onClick={handleCloseOffcanvas}
                                        >
                                            <div className="d-flex align-items-center text-lg font-medium">
                                                <FaChartLine/>
                                                &nbsp;&nbsp;Admin Tools
                                            </div>
                                        </Link>
                                    )}
                                    <Link
                                        to="/contracts"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaFileContract/>
                                            &nbsp;&nbsp;Contracts
                                        </div>
                                    </Link>
                                    <Link
                                        to="/dashboard"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaHome/>
                                            &nbsp;&nbsp;Dashboard
                                        </div>
                                    </Link>
                                    <a
                                        href="/device-provisioning"
                                        className="nav-link"
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <MdMobileFriendly/>
                                            &nbsp;&nbsp;Device Provisioning
                                        </div>
                                    </a>
                                    {(SessionService.canSystemAdmin() ||
                                        SessionService.canRtoAdmin() ||
                                        SessionService.canMasterAgentAdmin()) && (
                                        <Link
                                            to="/leads"
                                            className="nav-link"
                                            onClick={handleCloseOffcanvas}
                                        >
                                            <div className="d-flex align-items-center text-lg font-medium">
                                                <FaUserPlus/>
                                                &nbsp;&nbsp;Leads
                                            </div>
                                        </Link>
                                    )}
                                    <Link
                                        to="/referral-program"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <GiReceiveMoney/>
                                            &nbsp;&nbsp;Referral Program
                                        </div>
                                    </Link>
                                    <Link
                                        to="/reports"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaChartPie/>
                                            &nbsp;&nbsp;Reports
                                        </div>
                                    </Link>
                                    <Link
                                        to="/resources"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaBook/>
                                            &nbsp;&nbsp;Resources
                                        </div>
                                    </Link>
                                    <Link
                                        to="/settings"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaCogs/>
                                            &nbsp;&nbsp;Settings
                                        </div>
                                    </Link>
                                    <Link
                                        to="/trainings"
                                        className="nav-link"
                                        onClick={handleCloseOffcanvas}
                                    >
                                        <div className="d-flex align-items-center text-lg font-medium">
                                            <FaHeadset/>
                                            &nbsp;&nbsp;Trainings
                                        </div>
                                    </Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </div>
                </Container>
            </Navbar>
            <ToastContainer/>
        </>
    );
}

export default AuthHeader;
