import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledCurrencyInput = styled(CurrencyInput)({
	"& input": {
		textAlign: "right",
	},
});

const CustomInputNumberComponent = ({
	name,
	label,
	type,
	value,
	onChange,
	onBlur,
	className,
	error,
	helperText,
	...otherProps
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const [isTouched, setIsTouched] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const [errorMessage, setErrorMessage] = useState(helperText);

	useEffect(() => {
		validate(value);
	}, [value]);

	useEffect(() => {
		validate(internalValue);
	}, [internalValue]);

	const handleChange = (newValue) => {
		setInternalValue(newValue);
		validate(newValue); // Ensure validation is called with the new value
	};

	const handleInternalBlur = (event) => {
		setIsTouched(true);
		validate(internalValue);
		if (onBlur) {
			onBlur(event);
		}
	};

	const getFieldInfo = () => {
		return {
			decimalLimit: (type && type?.decimalLimit) || 0,
			maxValue: type && type?.maxValue,
			minValue: type && type?.minValue,
			placeholder: type && type?.placeholder,
			prefix: (type && type?.prefix) || "",
			readOnly: (type && type?.readOnly) || false,
			required: Boolean(type && type.required),
			suffix: (type && type?.suffix) || "",
			integerOnly: (type && type?.integerOnly) || false,
		};
	};

	const isValidDecimalNumber = (value) => {
		const fieldInfo = getFieldInfo();

		if (fieldInfo.decimalLimit === 0) {
			return isValidIntegerNumber(value);
		}

		let numberRegex = new RegExp(
			`^[0-9]+(\\.[0-9]{1,${fieldInfo.decimalLimit}})?$`
		);
		return numberRegex.test(value);
	};

	const isValidIntegerNumber = (value) => {
		let numberRegex = new RegExp(`^[0-9]+$`);
		return numberRegex.test(value);
	};

	const validate = (currentValue = internalValue) => {
		const fieldInfo = getFieldInfo();

		let valueToValidate =
			currentValue !== undefined ? currentValue : internalValue;

		const parsedValue =
			valueToValidate === "" || valueToValidate === "$"
				? ""
				: parseFloat(valueToValidate);
		if (fieldInfo.readOnly) {
			setIsValid(true);
			setErrorMessage("");
			onChange(name, valueToValidate, true);
			return;
		}

		if (fieldInfo.required) {
			if (valueToValidate === "" || valueToValidate === undefined) {
				setIsValid(false);
				setErrorMessage("This field is required.");
				onChange(name, valueToValidate, false);
				return;
			}

			if (
				!fieldInfo.integerOnly &&
				!isValidDecimalNumber(valueToValidate)
			) {
				setIsValid(false);
				setErrorMessage(
					`The value must be in a valid decimal format with ${fieldInfo.decimalLimit} decimal place.`
				);
				onChange(name, valueToValidate, false);
				return;
			}

			if (
				fieldInfo.integerOnly &&
				!isValidIntegerNumber(valueToValidate)
			) {
				setIsValid(false);
				setErrorMessage("Allowed integer numbers only.");
				onChange(name, valueToValidate, false);
				return;
			}
		}

		if (valueToValidate !== "") {
			let minValue = parseFloat(fieldInfo.minValue);
			let maxValue = parseFloat(fieldInfo.maxValue);

			// Check minValue
			if (typeof minValue === "number" && parsedValue < minValue) {
				setIsValid(false);
				setErrorMessage(`Minimum allowed number is ${minValue}.`);
				onChange(name, valueToValidate, false);
				return;
			}
			// Check maxValue
			if (typeof maxValue === "number" && parsedValue > maxValue) {
				setIsValid(false);
				setErrorMessage(`Maximum allowed number is ${maxValue}.`);
				onChange(name, valueToValidate, false);
				return;
			}
		}

		if (currentValue !== undefined && currentValue !== internalValue) {
			setInternalValue(currentValue);
		}

		setIsValid(true);
		setErrorMessage("");
		onChange(name, valueToValidate, true);
	};

	return (
		<div className={className}>
			<TextField
				id={name}
				label={label}
				variant="standard"
				size="small"
				placeholder={type?.placeholder || ""}
				fullWidth
				type="text" // Since we are using a custom input component
				value={internalValue}
				error={error ? error : isTouched && !isValid}
				helperText={
					helperText
						? helperText
						: isTouched && !isValid
						? errorMessage
						: ""
				}
				InputLabelProps={{
					shrink:
						internalValue !== undefined && internalValue !== null,
				}}
				InputProps={{
					inputComponent: StyledCurrencyInput,
					inputProps: {
						id: name,
						name: name,
						value: internalValue,
						onValueChange: handleChange,
						onBlur: handleInternalBlur,
						decimalScale: type?.integerOnly
							? 0
							: type?.decimalLimit,
						decimalsLimit: type?.decimalLimit
							? type?.decimalLimit
							: type?.integerOnly
							? 0
							: 2,
						allowNegativeValue: false,
						disabled: type?.readOnly,
						prefix: type?.prefix,
						suffix: type?.suffix,
						...otherProps,
					},
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default CustomInputNumberComponent;
