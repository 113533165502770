import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DynamicForm from "../formComponents/DynamicForm";
import { createDropdownColumn } from "../common/DropdownColumn";
import { createDateRangeColumn } from "../common/DateRangeColumn";
import { generateGridStructOptions } from "../../helpers/Utils";
import { confirmAlert } from "react-confirm-alert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
// import { Button } from "react-bootstrap";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FaPlus, FaBan, FaReply } from "react-icons/fa6";
import { getModelUrl } from "../../helpers/ModelHelper";
import SessionService from "../../service/SessionService";

var pluralize = require("pluralize");

const theme = createTheme({
	palette: {
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

const IndexTable = ({
	modelId,
	model,
	fetchCount = { current: 0 },
	loading,
	setLoading,
	service,
	title,
	actionButtons,
	labels = [],
	apiBase,
	successRedirectBase,
}) => {
	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [, setRowCount] = useState(10);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterList, setFilterList] = useState({});
	const [sortList, setSortList] = useState("id:desc");
	const [tableState, setTableState] = useState({});
	const [tableColumns, setTableColumns] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [lastAction, setLastAction] = useState("");
	const [filters, setFilters] = useState({});
	const tableRef = useRef(null);
	const [isUpdateMode, setIsUpdateMode] = useState(false);
	const [updateId, setUpdateId] = useState(null);
	const [modalKey, setModalKey] = useState(0);

	const [isDataLoaded, setIsDataLoaded] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	//const [currentActivity, setCurrentActivity] = useState(null);

	let isModelIndexView = modelId !== undefined;

	const IndexView = {
		page: currentPage, // <-- Set the default page
		limit: rowsPerPage, // <-- Set the default rows per page
		sort: sortList,
		filter: filterList,
		load_initial_data: true,
		load_initial_filters: true,
	};

	const fetchData = async () => {
		// Set loading state
		if (!isModelIndexView || (isModelIndexView && fetchCount.current > 0)) {
			setLoading(true);
		}

		try {
			fetchCount.current = fetchCount.current + 1;
			// console.log("modelId", modelId);
			// console.log("model", model);
			if (isModelIndexView) {
				if (title === "Contacts") {
					IndexView.filter = {
						...IndexView.filter,
						[model + "_id"]: modelId,
						deleted_by_id: null,
						// created_by_id: modelId,
					};
				} else if (title === "User Log") {
					let jsonKey;
					switch (model) {
						case "struct_store":
							jsonKey = "struct_store_id";
							break;
						case "struct_company":
							jsonKey = "struct_company_id";
							break;
						case "struct_organization":
							jsonKey = "struct_organization_id";
							break;
						default:
							jsonKey = "user_id";
							break;
					}
					IndexView.filter = {
						...IndexView.filter,
						[jsonKey]: modelId,
					};
				} else if (title === "CCN Transactions" || title === "Payoff Early Policies") {
					IndexView.filter = {
						...IndexView.filter,
						struct_store_id: modelId,
					};
				} else {
					IndexView.filter = {
						...IndexView.filter,
						model_id: modelId,
						model: model,
						deleted_by_id: null,
					};
				}
			}
			const response = await service.view(IndexView);

			// Display an error message if needed
			if (response.status !== 200) {
				toast.error(
					`Error fetching ${pluralize.plural(title)}: ${
						response.message
					}`,
					{
						position: "top-center",
						autoClose: 1000,
					}
				);
			}

			const data = response?.data?.data ? response?.data?.data : [];
			const rowCount = parseInt(data.length);
			const totalCount = parseInt(response?.data?.pagination?.count);
			const currentPage = parseInt(response?.data?.pagination?.page);
			const data_columns = response?.data?.columns;

			setData(data);
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			setRowCount(rowCount);
			setRowsPerPage(rowsPerPage);
			setTableColumns(data_columns);
			setIsDataLoaded(true);
		} catch (error) {
			console.error("Error fetching data:", error);
		}

		// Set loading state
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const sendRequest = async (filterList, isFilterChange) => {
		try {
			IndexView.page = tableState.page + 1;
			IndexView.limit = tableState.rowsPerPage;
			IndexView.filter = {
				...IndexView.filter,
				ranges: filters.ranges,
			};
			IndexView.sort = sortList;
			IndexView.load_initial_data = true;
			IndexView.load_initial_filters = true;

			if (tableState.sortOrder.hasOwnProperty("name")) {
				let sortColumn = tableState.sortOrder.name;
				if (tableColumns) {
					sortColumn = Object.keys(tableColumns).find(
						(key) => tableColumns[key].filter === sortColumn
					);
				}
				IndexView.sort = `${sortColumn}:${tableState.sortOrder.direction}`;
			} else {
				IndexView.sort = IndexView.sort || "id:desc";
			}
			setSortList(IndexView.sort);

			if (tableState.searchText) {
				if (isFilterChange) {
					IndexView.filter = {
						...IndexView.filter,
						all: {},
					};
					setSearchText("");
				} else {
					IndexView.filter = {
						...IndexView.filter,
						all: tableState.searchText,
					};
				}
			}

			if (isFilterChange) {
				let newFilterList = [];

				if (filterList) {
					newFilterList = filterList;
				} else {
					newFilterList = tableState.filterList;
					IndexView.filter.ranges = IndexView.filter.ranges || {}; // Preserve IndexView.filter.ranges
				}

				if (!columns || !tableState || !newFilterList) {
					console.error("Invalid columns or newTableState");
				} else {
					// Clear the IndexView.filter object
					IndexView.filter = {
						ranges: IndexView.filter.ranges, // Preserve IndexView.filter.ranges
					};

					// Loop through each column and set the filter in IndexView
					columns.forEach((column, index) => {
						// Skip the columns found in filters.ranges
						if (filters.ranges && column.name in filters.ranges) {
							// Skip this column
						} else {
							const filterValue = newFilterList[index][0];
							if (filterValue) {
								IndexView.filter = {
									...IndexView.filter,
									[column.name]: filterValue,
								};
							}
						}
					});

					setFilterList(IndexView.filter);
				}
			}

			if (
				!isModelIndexView ||
				(isModelIndexView && fetchCount.current > 0)
			) {
				setLoading(true);
			}
			fetchCount.current = fetchCount.current + 1;
			if (isModelIndexView) {
				if (title === "Contacts") {
					IndexView.filter = {
						...IndexView.filter,
						[model + "_id"]: modelId,
						deleted_by_id: null,
						// created_by_id: modelId,
					};
				} else if (title === "User Log") {
					let jsonKey;
					switch (model) {
						case "struct_store":
							jsonKey = "struct_store_id";
							break;
						case "struct_company":
							jsonKey = "struct_company_id";
							break;
						case "struct_organization":
							jsonKey = "struct_organization_id";
							break;
						default:
							jsonKey = "user_id";
							break;
					}
					IndexView.filter = {
						...IndexView.filter,
						[jsonKey]: modelId,
					};
				} else if (title === "CCN Transactions") {
					IndexView.filter = {
						...IndexView.filter,
						struct_store_id: modelId,
					};
				} else {
					IndexView.filter = {
						...IndexView.filter,
						model_id: modelId,
						model: model,
						deleted_by_id: null,
					};
				}
			}
			const response = await service.view(IndexView);
			const data = response?.data?.data ? response?.data?.data : [];
			const rowCount = parseInt(response?.data?.data?.length);
			const totalCount = parseInt(response?.data?.pagination?.count);
			const currentPage = parseInt(response?.data?.pagination?.page);
			const data_columns = response?.data?.columns;

			setData(data);
			setTotalCount(totalCount);
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			setRowCount(rowCount);
			setRowsPerPage(tableState.rowsPerPage);
			setTableColumns(data_columns);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "search") {
				// sendRequest(null, false);
				sendRequest(tableState.filterList, false); // <-- Pass the filterList
				setLastAction("");
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "search") {
				// sendRequest(null, false);
				sendRequest(tableState.filterList, false); // <-- Pass the filterList
				setLastAction(""); // Reset the last action
			}
			if (event.key === "Tab" && lastAction === "search") {
				// sendRequest(null, false);
				sendRequest(tableState.filterList, false); // <-- Pass the filterList
				setLastAction("");
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [searchText, lastAction]);

	useEffect(() => {
		const handleClickOutside = async (event) => {
			if (lastAction === "filterChange") {
				// sendRequest(null, true);
				sendRequest(tableState.filterList, true); // <-- Pass the filterList
				setLastAction(""); // Reset the last action
			}
		};

		const handleEnterRelease = async (event) => {
			if (event.key === "Enter" && lastAction === "filterChange") {
				// sendRequest(null, true);
				sendRequest(tableState.filterList, true); // <-- Pass the filterList
				setLastAction(""); // Reset the last action
			}
			if (event.key === "Tab" && lastAction === "filterChange") {
				// sendRequest(null, true);
				sendRequest(tableState.filterList, true); // <-- Pass the filterList
				setLastAction(""); // Reset the last action
			}
		};

		// Add event listener
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("keyup", handleEnterRelease);
		// Clean up
		return () => {
			document.removeEventListener("click", handleClickOutside);
			document.removeEventListener("keyup", handleEnterRelease);
		};
	}, [filters, lastAction]);

	const handleTableChange = async (action, newTableState) => {
		if (JSON.stringify(tableState) !== JSON.stringify(newTableState)) {
			setTableState(newTableState);
		}

		if (action === "search") {
			setSearchText(newTableState.searchText);
			setLastAction(action);
		}

		if (action === "filterChange") {
			// Assuming filters are structured as an object with column names as keys
			setFilters((prevFilters) => ({
				...newTableState.filterList.reduce((acc, filter, index) => {
					acc[newTableState.columns[index].name] = filter;
					return acc;
				}, {}),
				ranges: prevFilters.ranges, // Keep the ranges property
			}));
			setLastAction(action);
		}

		if (
			action === "changePage" ||
			action === "changeRowsPerPage" ||
			action === "sort" ||
			action === "resetFilters"
		) {
			try {
				IndexView.page = newTableState.page + 1;
				IndexView.limit = newTableState.rowsPerPage;
				IndexView.filter = {
					...IndexView.filter,
					ranges: IndexView.filter.ranges,
				};
				IndexView.sort = sortList;

				if (newTableState.sortOrder.hasOwnProperty("name")) {
					let sortColumn = newTableState.sortOrder.name;
					if (tableColumns) {
						sortColumn = Object.keys(tableColumns).find(
							(key) => tableColumns[key].filter === sortColumn
						);
					}
					IndexView.sort = `${sortColumn}:${newTableState.sortOrder.direction}`;
				} else {
					IndexView.sort = "id:desc";
				}
				setSortList(IndexView.sort);

				if (newTableState.searchText) {
					if (newTableState.searchText.length > 2) {
						IndexView.filter = {
							...IndexView.filter,
							all: newTableState.searchText,
						};
					} else {
						return false;
					}
				}

				if (newTableState.filterList) {
					// Ensure both columns and tableState are provided and valid
					if (
						!columns ||
						!newTableState ||
						!newTableState.filterList
					) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in IndexView
						if (columns.length > 0) {
							columns.forEach((column, index) => {
								const filterValue =
									newTableState.filterList[index][0];
								if (filterValue) {
									IndexView.filter = {
										...IndexView.filter,
										[column.name]: filterValue,
									};
								}
							});
						}
					}
					setFilterList(IndexView.filter);
				}

				if (action === "resetFilters") {
					IndexView.filter = {};
					setFilterList({});
				}

				if (
					!isModelIndexView ||
					(isModelIndexView && fetchCount.current > 0)
				) {
					setLoading(true);
				}
				fetchCount.current = fetchCount.current + 1;
				if (isModelIndexView) {
					if (title === "Contacts") {
						IndexView.filter = {
							...IndexView.filter,
							[model + "_id"]: modelId,
							deleted_by_id: null,
							// created_by_id: modelId,
						};
					} else if (title === "User Log") {
						let jsonKey;
						switch (model) {
							case "struct_store":
								jsonKey = "struct_store_id";
								break;
							case "struct_company":
								jsonKey = "struct_company_id";
								break;
							case "struct_organization":
								jsonKey = "struct_organization_id";
								break;
							default:
								jsonKey = "user_id";
								break;
						}
						IndexView.filter = {
							...IndexView.filter,
							[jsonKey]: modelId,
						};
					} else if (title === "CCN Transactions" || title === "Payoff Early Policies") {
						IndexView.filter = {
							...IndexView.filter,
							struct_store_id: modelId,
						};
					} else {
						IndexView.filter = {
							...IndexView.filter,
							model_id: modelId,
							model: model,
							deleted_by_id: null,
						};
					}
				}
				const response = await service.view(IndexView);
				const data = response?.data?.data ? response?.data?.data : [];
				const rowCount = parseInt(response?.data?.data?.length);
				const totalCount = parseInt(response?.data?.pagination?.count);
				const currentPage = parseInt(response?.data?.pagination?.page);

				setData(data);
				setTotalCount(totalCount);
				setCurrentPage(currentPage);
				setTotalPages(totalPages);
				setRowCount(rowCount);
				setRowsPerPage(newTableState.rowsPerPage);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.error("Error fetching data:", error);
			}
		}
	};

	const handleAddAction = () => {
		setIsUpdateMode(false);
		setIsModalOpen(true); // Show the modal
		setModalKey((prevKey) => prevKey + 1);
	};

	const handleUpdateAction = (rowData) => {
		setUpdateId(rowData[1]);
		setIsUpdateMode(true);
		setIsModalOpen(true);
		setModalKey((prevKey) => prevKey + 1);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	// const handleFormSubmit = async (data) => {
	// 	Logic to submit the form data
	// 	You can use the 'service' prop to make API calls
	// };

	const handleDoneAction = (tableMeta) => {
		confirmAlert({
			title: "Are you sure?",
			message: "You want to mark this reminder as done?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						markAsDone(
							tableMeta.rowData[1],
							tableMeta.rowData[8],
							tableMeta.rowData[9]
						);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const markAsDone = async (reminderId, modelId, model) => {
		// console.log(reminderId, modelId, model);
		return await service
			.markAsDone(reminderId, modelId, model)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(`Reminder marked as done!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				}
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	const handleDeleteAction = (tableMeta) => {
		confirmAlert({
			title: "Are you sure?",
			message: "You want to delete this data?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteRecord(tableMeta.rowData[1]);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const deleteRecord = async (modelId) => {
		return await service
			.delete(modelId)
			.then((response) => {
				if (response.status === 200 && response.data.isOk === true) {
					// show success message
					toast.info(`Data deleted successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				}
			})
			.catch((error) => {
				// setError("api", {
				// 	type: "server",
				// 	message: error.response.data.error,
				// });
			});
	};

	const handleVoidAction = (tableMeta) => {
		let recordData = {
			get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0,
			struct_store_id: tableMeta.rowData[3],
			data: {
				retref: tableMeta.rowData[1],
				mid: tableMeta.rowData[2],
			},
		};

		confirmAlert({
			title: "Are you sure?",
			message: "You want to void this payment?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						voidRecord(recordData);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const voidRecord = async (recordData) => {
		return await service
			.voidPayment(recordData)
			.then((response) => {
				console.log("response", response);
				if (response?.status === 200 && response?.data?.isOk === true) {
					// show success message
					toast.info(`Payment voided successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				} else {
					toast.error(`Error voiding payment: ${response?.msg}`, {
						position: "top-center",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error(`Error voiding payment: ${error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	const handleRefundAction = (tableMeta) => {
		let recordData = {
			get_uat: process.env.REACT_APP_CCN_GET_UAT === "true" ? 1 : 0,
			struct_store_id: tableMeta.rowData[3],
			data: {
				retref: tableMeta.rowData[1],
				mid: tableMeta.rowData[2],
			},
		};

		confirmAlert({
			title: "Are you sure?",
			message: "You want to refund this payment?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						refundRecord(recordData);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const refundRecord = async (recordData) => {
		return await service
			.refundPayment(recordData)
			.then((response) => {
				console.log("response", response);
				if (response?.status === 200 && response?.data?.isOk === true) {
					// show success message
					toast.info(`Payment refunded successfully!`, {
						position: "top-center",
						autoClose: 1000,
					});
					fetchData();
				} else {
					toast.error(`Error refunding payment: ${response?.msg}`, {
						position: "top-center",
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error(`Error refunding payment: ${error}`, {
					position: "top-center",
					autoClose: 1000,
				});
			});
	};

	let columns = labels.map((label) => {
		let newColumn = {
			name: label.id,
			label: label.label,
			options: generateGridStructOptions(
				label.id,
				tableColumns,
				label.initialDisplay,
				label.filterDisplay,
				label.columnsDisplay,
				label.excluded
			),
		};

		if (
			tableColumns &&
			Object.keys(tableColumns).length > 0 &&
			tableColumns.hasOwnProperty(label.id)
		) {
			const column = tableColumns[label.id];

			// Handle type cases
			if (
				column.type === "SELECT" &&
				tableColumns[label.id].data !== undefined
			) {
				if (label.link) {
					newColumn = createDropdownColumn(
						tableColumns,
						label.id,
						label.label,
						label.link
					);
					return newColumn;
				} else {
					newColumn = createDropdownColumn(
						tableColumns,
						label.id,
						label.label
					);
					return newColumn;
				}
			} else if (column.type === "TIMESTAMP") {
				newColumn = createDateRangeColumn(
					tableColumns,
					IndexView,
					filters,
					setFilters,
					label.id,
					label.label,
					label.initialDisplay,
					label.displayTime
				);
				return newColumn;
			} else if (column.type === "DATETIME") {
				newColumn = createDateRangeColumn(
					tableColumns,
					IndexView,
					filters,
					setFilters,
					label.id,
					label.label,
					label.initialDisplay,
					label.displayTime
				);
				return newColumn;
			} else if (column.type === "DATE") {
				newColumn = createDateRangeColumn(
					tableColumns,
					IndexView,
					filters,
					setFilters,
					label.id,
					label.label
				);
				return newColumn;
			} else if (column.type === "TEXT") {
				return newColumn;
			} else if (column.type === "MEDIUMTEXT") {
				return newColumn;
			} else if (column.type === "VARCHAR") {
				// if label has link property, create a link column
				if (label.model_link && label.id === "model_name") {
					newColumn = {
						name: label.id,
						label: label.label,
						options: {
							filter: true,
							sort: true,
							display: true,
							viewColumns: false,
							customBodyRender: (_, tableMeta) => {
								let modelName = null;
								let modelId = null;

								if (tableMeta.rowData[2] !== null) {
									modelName = "struct_iso";
									modelId = tableMeta.rowData[2];
								} else if (tableMeta.rowData[3] !== null) {
									modelName = "struct_master_agent";
									modelId = tableMeta.rowData[3];
								} else if (tableMeta.rowData[4] !== null) {
									modelName = "struct_organization";
									modelId = tableMeta.rowData[4];
								} else if (tableMeta.rowData[5] !== null) {
									modelName = "struct_company";
									modelId = tableMeta.rowData[5];
								} else if (tableMeta.rowData[6] !== null) {
									modelName = "struct_store";
									modelId = tableMeta.rowData[6];
								} else if (tableMeta.rowData[7] !== null) {
									modelName = "leads";
									modelId = tableMeta.rowData[7];
								} else if (tableMeta.rowData[8] !== null) {
									modelName = "onboarding";
									modelId = tableMeta.rowData[8];
								}

								if (modelName) {
									let path = getModelUrl(modelId, modelName);
									return (
										<Link to={`${path}`}>
											{tableMeta.rowData[9]}
										</Link>
									);
								}
							},
						},
					};
					return newColumn;
				}
				if (label.model_link_alt && label.id === "model_name") {
					newColumn = {
						name: label.id,
						label: label.label,
						options: {
							filter: true,
							sort: true,
							display: true,
							viewColumns: false,
							customBodyRender: (_, tableMeta) => {
								let modelName = tableMeta.rowData[9];
								let modelId = tableMeta.rowData[8];

								if (modelName) {
									let path = getModelUrl(modelId, modelName);
									return (
										<Link to={`${path}`}>
											{tableMeta.rowData[10]}
										</Link>
									);
								}
							},
						},
					};
					return newColumn;
				}
				return newColumn;
			} else if (column.type === "INT") {
				return newColumn;
			} else if (column.type === "DECIMAL") {
				newColumn.options = {
					...newColumn.options,
					customBodyRender: (value, tableMeta, updateValue) => {
						// Check if the currency label is set to true
						if (label.currency) {
							return `$${value.toFixed(2)}`;
						} else {
							return value.toFixed(2);
						}
					},
				};
				return newColumn;
			}
		}

		return newColumn;
	});

	// Prepend a column with action buttons
	if (actionButtons && Object.keys(actionButtons).length > 0) {
		columns.unshift({
			name: "",
			options: {
				filter: false,
				sort: false,
				display: true,
				viewColumns: false,
				customBodyRender: (_, tableMeta) => (
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						{Object.keys(actionButtons).map((key, index) => {
							if (
								actionButtons.hasOwnProperty(key) &&
								key === "updateLink"
							) {
								return (
									<Link
										key={index}
										to={`${actionButtons[key].path}/${tableMeta.rowData[1]}`}
									>
										<ModeEditIcon
											variant="outlined"
											color="primary"
											sx={{
												border: 1,
												borderRadius: "5px",
												marginRight: ".5rem",
											}}
										>
											{key}
										</ModeEditIcon>
									</Link>
								);
							}
							if (
								actionButtons.hasOwnProperty(key) &&
								key === "updateModal"
							) {
								return (
									<ModeEditIcon
										key={index}
										variant="outlined"
										color="primary"
										sx={{
											border: 1,
											borderRadius: "5px",
											marginRight: ".5rem",
										}}
										onClick={() =>
											handleUpdateAction(
												tableMeta.rowData
											)
										}
										style={{ cursor: "pointer" }}
									>
										{key}
									</ModeEditIcon>
								);
							}
							if (
								actionButtons.hasOwnProperty(key) &&
								key === "viewLink"
							) {
								return (
									<Link
										key={index}
										to={`${actionButtons[key].path}/${tableMeta.rowData[1]}`}
									>
										<VisibilityIcon
											variant="outlined"
											color="primary"
											sx={{
												border: 1,
												borderRadius: "5px",
												marginRight: ".5rem",
											}}
										>
											{key}
										</VisibilityIcon>
									</Link>
								);
							}

							if (
								actionButtons.hasOwnProperty(key) &&
								key === "viewHref"
							) {
								const currentPath = window.location.pathname; // Get the current path
								return (
									<a
										key={index}
										href={`${currentPath}/${actionButtons[key].path}/${tableMeta.rowData[1]}`}
									>
										<VisibilityIcon
											variant="outlined"
											color="primary"
											sx={{
												border: 1,
												borderRadius: "5px",
												marginRight: ".5rem",
											}}
										>
											{key}
										</VisibilityIcon>
									</a>
								);
							}

							if (
								actionButtons.hasOwnProperty(key) &&
								key === "markAsDone"
							) {
								// only show the done icon if the reminder status is not done
								return (
									(tableMeta.rowData[3] === 1 ||
										tableMeta.rowData[3] === 3) && (
										<DoneIcon
											key={index}
											variant="outlined"
											color="success"
											onClick={() =>
												handleDoneAction(tableMeta)
											}
											sx={{
												border: 1,
												borderRadius: "5px",
												marginRight: ".5rem",
											}}
											style={{ cursor: "pointer" }}
										></DoneIcon>
									)
								);
							}

							if (
								actionButtons.hasOwnProperty(key) &&
								key === "delete"
							) {
								return (
									<DeleteIcon
										key={index}
										variant="outlined"
										color="error"
										onClick={() =>
											handleDeleteAction(tableMeta)
										}
										sx={{
											border: 1,
											borderRadius: "5px",
											// marginRight: ".5rem",
										}}
										style={{ cursor: "pointer" }}
									></DeleteIcon>
								);
							}

							if (
								actionButtons.hasOwnProperty(key) &&
								(tableMeta.rowData[4] !== 7 &&
									tableMeta.rowData[4] !== 12) &&
								key === "void"
							) {
								return (
									<FaBan
										key={index}
										onClick={() =>
											handleVoidAction(tableMeta)
										}
										title="Void"
										style={{
											border: "1px solid #d32f2f",
											borderRadius: "5px",
											padding: ".2rem",
											width: "1.5rem",
											height: "1.5rem",
											marginRight: ".5rem",
											cursor: "pointer",
											color: "#d32f2f",
										}}
									></FaBan>
								);
							}

							if (
								actionButtons.hasOwnProperty(key) &&
								(tableMeta.rowData[4] !== 7 &&
									tableMeta.rowData[4] !== 12) &&
								key === "refund"
							) {
								return (
									<FaReply
										key={index}
										onClick={() =>
											handleRefundAction(tableMeta)
										}
										title="Refund"
										style={{
											border: "1px solid #2e7d32",
											borderRadius: "5px",
											padding: ".2rem",
											width: "1.5rem",
											height: "1.5rem",
											marginRight: ".5rem",
											cursor: "pointer",
											color: "#2e7d32",
										}}
									></FaReply>
								);
							}
							return null;
						})}
					</div>
				),
			},
		});
	}

	const HeaderElements = () => (
		<>
			{actionButtons &&
				Object.keys(actionButtons).length > 0 &&
				actionButtons.hasOwnProperty("create") && (
					<Button
						size="small"
						variant="contained"
						onClick={handleAddAction}
					>
						<div className="d-flex align-items-center">
							<FaPlus />
							&nbsp;
							<span className="font-medium">
								Add {pluralize.singular(title)}
							</span>
						</div>
					</Button>
				)}
		</>
	);

	const options = {
		download: SessionService.getIsVpn(),
		customToolbar: () => <HeaderElements />,
		filter: true,
		filterType: "textField",
		searchText: searchText,
		onFilterChipClose: (removedFilterIndex, value, filterList) => {
			// Get the name of the removed filter
			const removedFilterName = columns[removedFilterIndex].name;
			// Check if the removed filter is in the ranges object
			if (
				filters.ranges &&
				filters.ranges.hasOwnProperty(removedFilterName)
			) {
				// Clear the range from IndexView.filter.ranges
				if (IndexView.filter.ranges) {
					delete IndexView.filter.ranges[removedFilterName];
				}
				// Clear the range from filters.ranges
				delete filters.ranges[removedFilterName];
				// Update the state
				setFilters(filters);
			}
			sendRequest(filterList, true);
			setLastAction("");
		},
		serverSide: true,
		count: totalCount,
		page: currentPage - 1,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [
			10, 25, 50, 100, 500, 1000,
			// ...(totalCount > 1000
			// 	? [500, 1000]
			// 	: [{ label: "All", value: totalCount }
			// 	]),
			//{ label: "All", value: totalCount },
		],
		elevation: 0,
		responsive: "standard",
		fixedHeader: true,
		fixedFooter: true,
		selectableRows: "none",
		onTableChange: handleTableChange,
		onDownload: (buildHead, buildBody, columns, data) => {
			// create export column list
			let exportColumns = labels.map((label) => {
				let newColumn = {
					name: label.id,
					label: label.label,
					print: true,
					download: true,
				};
				return newColumn;
			});
			// Fetch all data that matches the applied filters
			const fetchAllData = async () => {
				// Use the same parameters as in the handleTableChange function
				IndexView.page = 1;
				IndexView.limit = totalCount;
				IndexView.filter = filterList;
				IndexView.sort = sortList;

				// Add filters based on the current table state
				if (tableState.filterList) {
					// Ensure both exportColumns and tableState are provided and valid
					if (
						!exportColumns ||
						!tableState ||
						!tableState.filterList
					) {
						console.error("Invalid columns or newTableState");
					} else {
						// Loop through each column and set the filter in IndexView
						exportColumns.forEach((column, index) => {
							const filterValue = tableState.filterList[index][0];

							if (filterValue) {
								IndexView.filter = {
									...IndexView.filter,
									[column.name]: filterValue,
								};
							}
						});
					}
				}

				if (isModelIndexView) {
					IndexView.filter = {
						...IndexView.filter,
						model_id: modelId,
						model: model,
						deleted_by_id: null,
					};
				}
				const response = await service.view(IndexView);
				return response.data.data;
			};

			fetchAllData().then((allData) => {
				// Check if allData is defined
				if (!allData) {
					console.error("allData is undefined");
					return false;
				}

				// get checked columns
				// const checkedColumns = tableState.columns.filter(
				// 	(column) => column.display === "true"
				// );

				// // Transform the checked columns to the format required by buildHead
				const transformedColumns = exportColumns.map((column) => ({
					...column,
				}));

				// remove first column, null empty
				transformedColumns.shift();

				// Check if there are no columns selected
				if (transformedColumns.length === 0) {
					// Optionally display a message to the user
					toast.error("No columns selected for download", {
						position: "top-center",
						autoClose: 2000,
					});
					return false;
				}

				// Transform data to the required format
				const transformedData = allData
					.map((item, index) => {
						if (item) {
							return {
								index: index,
								data: transformedColumns.map(
									(column) => item[column.name]
								),
							};
						}
						return null;
					})
					.filter((item) => item !== null);

				// Generate CSV content
				const csvContent =
					buildHead(transformedColumns) + buildBody(transformedData);

				var fileDownload = require("js-file-download");
				fileDownload(csvContent, "export.csv", "text/csv");
			});

			// Return false to prevent the default CSV export
			return false;
		},
	};

	return (
		<div>
			<ThemeProvider theme={theme}>
				<div style={{ opacity: isDataLoaded ? 1 : 0 }}>
					<MUIDataTable
					    key={SessionService.getIsVpn()}
						title={title}
						data={data}
						columns={columns}
						options={options}
						ref={tableRef}
					/>
				</div>
				{isDataLoaded && isModalOpen && (
					<>
					<DynamicForm
						loading={loading}
						setLoading={setLoading}
						model={model}
						modelId={modelId}
						modelLabel={pluralize.singular(title)}
						service={service}
						apiBase={apiBase}
						successRedirectBase={successRedirectBase}
						display="modal"
						isOpen={isModalOpen}
						onClose={handleModalClose}
						scenario={isUpdateMode ? "update" : "create"}
						updateId={updateId}
						fetchData={fetchData}
						key={modalKey}
					/>
					{console.log("modelId", modelId)}
					</>
				)}
			</ThemeProvider>
		</div>
	);
};

export default IndexTable;
